// File: Login.jsx

import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import axios from 'axios';
import { Label } from "../ui/label.jsx";
import { Input } from "../ui/input.jsx";
import { Button } from "../ui/button.jsx";

const Login = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const history = useHistory();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, {
                username,
                password,
            });

            if (response.status === 200) {
                console.log("Captured session token: ", response.data.token);
                localStorage.setItem('token', response.data.token); // Store token in localStorage
                localStorage.setItem('username', response.data.username); // Store username in localStorage
                onLogin(response.data.token, response.data.username);
                history.push('/finance-applications');
            } else {
                console.error('Login failed');
            }
        } catch (error) {
            console.error('An error occurred during login:', error);
            setErrorMessage('Invalid username or password');
            setTimeout(() => {
                setErrorMessage('');
            }, 2000); // Clear error message after 2 seconds
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-900">
            <div className="w-full max-w-md space-y-4 rounded-lg bg-white p-6 shadow-lg dark:bg-gray-900">
                <h2 className="text-3xl mb-6 text-white font-semibold">Login</h2>
                {errorMessage && ( // Display error message if present
                    <div className="bg-red-200 text-red-800 rounded p-2 mb-4">
                        {errorMessage}
                    </div>
                )}
                <form onSubmit={handleLogin} className="space-y-4">
                    <div className="space-y-1">
                        <Label htmlFor="username" className="text-white">Username</Label>
                        <Input
                            id="username"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className="space-y-1">
                        <Label htmlFor="password" className="text-white">Password</Label>
                        <Input
                            id="password"
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <Button className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Login
                    </Button>
                    <p className="text-center mt-2 text-white">
                        Don't have an account? <Link to="/register" className="text-blue-500">Register</Link>
                    </p>
                </form>
            </div>
        </div>
    );
};

export default Login;
