import React from 'react';
import { Button } from "../ui/button.jsx";

const ConfirmationModal = ({ isOpen, onCancel, onConfirm }) => {
    return (
        <div className={`fixed inset-0 flex items-center justify-center ${isOpen ? '' : 'hidden'}`}>
            <div className="absolute inset-0 bg-gray-800 opacity-75" onClick={onCancel}></div>
            <div className="bg-white p-4 rounded-lg z-10">
                <p className="text-gray-900 mb-4">Are you sure you want to delete this record?</p>
                <div className="flex justify-between">
                    <Button onClick={onCancel} className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline mr-2">
                        Cancel
                    </Button>
                    <Button onClick={onConfirm} className="bg-red-600 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline">
                        Delete
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;
