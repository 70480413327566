import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Label } from "../ui/label.jsx";
import { Input } from "../ui/input.jsx";
import { Button } from "../ui/button.jsx";

const Register = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [registrationSuccess, setRegistrationSuccess] = useState(false);
    const [registrationError, setRegistrationError] = useState('');

    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            // Check if username already exists (case-insensitive)
            const checkResponse = await axios.get(`${process.env.REACT_APP_API_URL}/auth/check-username?username=${username}`);

            if (!checkResponse.data.available) {
                setRegistrationError('Username already exists');
                setTimeout(() => {
                    setRegistrationError('');
                }, 2000); // Message disappears after 2 seconds
                return;
            }

            // Make API call to register user
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/register`, { username, password });

            // Assuming the API returns a success status code
            if (response.status === 201) {
                // Reset registration error state
                setRegistrationError('');
                // Set registration success state to true
                setRegistrationSuccess(true);
                setTimeout(() => {
                    setRegistrationSuccess(false);
                }, 2000); // Message disappears after 2 seconds
            } else {
                // Handle other response statuses or error cases
                console.error('Registration failed:', response.data);
            }
        } catch (error) {
            console.error('Error during registration:', error);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-900">
            <div className="w-full max-w-md space-y-4 rounded-lg bg-white p-6 shadow-lg dark:bg-gray-900">
                <h2 className="text-3xl mb-6 text-white font-semibold">Register</h2>
                {registrationSuccess && (
                    <div className="bg-green-200 text-green-800 rounded p-2 mb-4">
                        Registration successful
                    </div>
                )}
                {registrationError && (
                    <div className="bg-red-200 text-red-800 rounded p-2 mb-4">
                        {registrationError}
                    </div>
                )}
                <form onSubmit={handleRegister} className="space-y-4">
                    <div className="space-y-1">
                        <Label htmlFor="username" className="text-white">Username</Label>
                        <Input
                            id="username"
                            type="text"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                        />
                    </div>
                    <div className="space-y-1">
                        <Label htmlFor="password" className="text-white">Password</Label>
                        <Input
                            id="password"
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                        />
                    </div>
                    <Button type="submit" className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                        Register
                    </Button>
                </form>
                <div className="mt-4 text-center">
                    <Link to="/login" className="text-blue-500 hover:underline">Back to Login</Link>
                </div>
            </div>
        </div>
    );
};

export default Register;
