import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios'; // Import axios for making HTTP requests
import financeLogo from '../../images/finance_logo.png'; // Import the image

const NavBar = ({ loggedIn, username }) => {
    const history = useHistory();

    const handleLogout = async () => {
        try {
            // Make a request to the backend logout endpoint
            await axios.post(`${process.env.REACT_APP_API_URL}/auth/logout`, {}, {
                withCredentials: true // Include credentials in the request
            });
            // Clear the localStorage
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            // Redirect to the login page after logout
            history.push('/login');
            window.location.reload(); // Reload the page to ensure immediate logout
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    return (
        <nav className="flex justify-between bg-gray-800 p-4">
            <div>
                {/* Use the image as the logo */}
                <img src={financeLogo} alt="Finance Logo" className="h-10" />
            </div>
            <div>
                {loggedIn && (
                    <div className="flex items-center">
                        <span className="text-white mr-2">Welcome, {username}</span>
                        <button onClick={handleLogout} className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded">Logout</button>
                    </div>
                )}
            </div>
        </nav>
    );
};

export default NavBar;
