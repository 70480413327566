import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import FinanceForm from './components/Finance/FinanceForm';
import FinanceList from './components/Finance/FinanceList';
import NavBar from './components/ui/NavBar';

const App = () => {
  const [token, setToken] = useState(null);
  const [username, setUsername] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedUsername = localStorage.getItem('username');
    if (storedToken) {
      setToken(storedToken);
      setUsername(storedUsername);
    }
  }, []);

  const handleLogin = (token, username) => {
    setToken(token);
    setUsername(username);
    localStorage.setItem('token', token);
    localStorage.setItem('username', username);
  };

  const handleLogout = () => {
    setToken(null);
    setUsername(null);
    localStorage.removeItem('token');
    localStorage.removeItem('username');
  };

  return (
    <Router>
      <div>
        <NavBar loggedIn={!!token} handleLogout={handleLogout} username={username} />
        <div>
          <Switch>
            <Route exact path="/">
              {token ? <Redirect to="/finance-applications" /> : <Redirect to="/login" />}
            </Route>
            <Route exact path="/login">
              {token ? <Redirect to="/finance-applications" /> : <Login onLogin={handleLogin} />}
            </Route>
            <Route exact path="/register">
              {token ? <Redirect to="/finance-applications" /> : <Register onLogin={handleLogin} />}
            </Route>
            <Route exact path="/finance-applications">
              {!token ? <Redirect to="/login" /> : <FinanceList token={token} handleLogout={handleLogout} />}
            </Route>
            <Route exact path="/finance-application/:id">
              {!token ? <Redirect to="/login" /> : <FinanceForm token={token} />}
            </Route>
            <Route render={() => <Login onLogin={handleLogin} />} />
          </Switch>
        </div>
      </div>
    </Router>
  );
};

export default App;
