import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Label } from "../ui/label.jsx";
import { Input } from "../ui/input.jsx";
import { Button } from "../ui/button.jsx";
import ConfirmationModal from "../ui/confirmationModal.jsx";

const FinanceList = ({ token, handleLogout }) => {
    const [financeApplications, setFinanceApplications] = useState([]);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        income: '',
        expenses: '',
        assets: '',
        liabilities: ''
    });
    const [editingId, setEditingId] = useState(null);
    const [creatingNew, setCreatingNew] = useState(false);
    const [showingRecord, setShowingRecord] = useState(null);
    const [deleteConfirmationId, setDeleteConfirmationId] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const fetchFinanceApplications = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/finance`, {
                    headers: { Authorization: token }
                });
                setFinanceApplications(response.data);
            } catch (error) {
                console.error('Error fetching finance applications:', error);
            }
        };

        // const fetchUserData = async () => {
        //     try {
        //         const response = await axios.get(`${process.env.REACT_APP_API_URL}/user`, {
        //             headers: { Authorization: token }
        //         });
        //         setUser(response.data);
        //     } catch (error) {
        //         console.error('Error fetching user data:', error);
        //     }
        // };

        fetchFinanceApplications();
        // fetchUserData();
    }, [token]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // If the input field is for firstName or lastName, restrict input to only alphabets
        if (name === 'firstName' || name === 'lastName') {
            // Regex pattern to allow only alphabets and space
            const regex = /^[A-Za-z\s]*$/;
            if (value === '' || regex.test(value)) {
                setFormData({
                    ...formData,
                    [name]: value
                });
            }
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (editingId) {
                await axios.put(`${process.env.REACT_APP_API_URL}/finance/${editingId}`, formData, {
                    headers: { Authorization: token }
                });
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/finance`, formData, {
                    headers: { Authorization: token }
                });
            }
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/finance`, {
                headers: { Authorization: token }
            });
            setFinanceApplications(response.data);
            setFormData({
                firstName: '',
                lastName: '',
                income: '',
                expenses: '',
                assets: '',
                liabilities: ''
            });
            setEditingId(null);
            setCreatingNew(false);
        } catch (error) {
            console.error('Error submitting finance application:', error);
        }
    };

    const handleEdit = (id) => {
        const financeApp = financeApplications.find(app => app._id === id);
        if (financeApp) {
            setFormData({
                firstName: financeApp.firstName,
                lastName: financeApp.lastName,
                income: financeApp.income,
                expenses: financeApp.expenses,
                assets: financeApp.assets,
                liabilities: financeApp.liabilities
            });
            setEditingId(id);
            setCreatingNew(true);
        }
    };

    const handleDeleteConfirmation = (id) => {
        setDeleteConfirmationId(id);
    };

    const handleDelete = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/finance/${deleteConfirmationId}`, {
                headers: { Authorization: token }
            });
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/finance`, {
                headers: { Authorization: token }
            });
            setFinanceApplications(response.data);
            setDeleteConfirmationId(null);
        } catch (error) {
            console.error('Error deleting finance application:', error);
        }
    };

    const handleCloseDetails = () => {
        setShowingRecord(null);
    };

    const handleCancel = () => {
        setFormData({
            firstName: '',
            lastName: '',
            income: '',
            expenses: '',
            assets: '',
            liabilities: ''
        });
        setCreatingNew(false);
    };

    return (
        <div>
            <div className="min-h-screen flex items-center justify-center bg-gray-900">
                <div className="w-full max-w-md space-y-4 rounded-lg bg-white p-6 shadow-lg dark:bg-gray-900">
                    <h2 className="text-3xl mb-6 text-white font-semibold">Finance Records</h2>
                    {creatingNew ? (
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <div className="space-y-1">
                                <Label htmlFor="firstName" className="text-white">First Name:</Label>
                                <Input type="text" name="firstName" value={formData.firstName} onChange={handleInputChange} required />
                            </div>
                            <div className="space-y-1">
                                <Label htmlFor="lastName" className="text-white">Last Name:</Label>
                                <Input type="text" name="lastName" value={formData.lastName} onChange={handleInputChange} required />
                            </div>
                            <div className="space-y-1">
                                <Label htmlFor="income" className="text-white">Income:</Label>
                                <Input type="number" name="income" value={formData.income} onChange={handleInputChange} required />
                            </div>
                            <div className="space-y-1">
                                <Label htmlFor="expenses" className="text-white">Expenses:</Label>
                                <Input type="number" name="expenses" value={formData.expenses} onChange={handleInputChange} required />
                            </div>
                            <div className="space-y-1">
                                <Label htmlFor="assets" className="text-white">Assets:</Label>
                                <Input type="number" name="assets" value={formData.assets} onChange={handleInputChange} required />
                            </div>
                            <div className="space-y-1">
                                <Label htmlFor="liabilities" className="text-white">Liabilities:</Label>
                                <Input type="number" name="liabilities" value={formData.liabilities} onChange={handleInputChange} required />
                            </div>
                            <Button type="button" onClick={handleCancel} className="w-1/2 mb-2 bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                                Cancel
                            </Button>
                            <Button type="submit" className="w-1/2 mb-2 bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                                Create
                            </Button>
                        </form>
                    ) : (
                        <Button onClick={() => setCreatingNew(true)} className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                            Create new record
                        </Button>
                    )}
                    <ul>
                        {financeApplications.map(app => (
                            <li key={app._id} className="mb-4">
                                <div className="flex justify-between items-center">
                                    <div className="text-white">{app.firstName}</div>
                                    <div className="text-white">{app.lastName}</div>
                                    <div className="flex">
                                        <Button onClick={() => handleEdit(app._id)} className="mr-2 bg-blue-600 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline">
                                            Edit
                                        </Button>
                                        <Button onClick={() => handleDeleteConfirmation(app._id)} className="mr-2 bg-red-600 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline">
                                            Delete
                                        </Button>
                                        <Button onClick={() => setShowingRecord(app._id)} className="bg-green-600 hover:bg-green-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline">
                                            Show
                                        </Button>
                                    </div>
                                </div>
                                {showingRecord === app._id && (
                                    <div className="absolute top-0 left-0 w-full h-full bg-gray-800 bg-opacity-75 flex items-center justify-center">
                                        <div className="bg-white p-4 rounded-lg">
                                            <div className="mb-2"><strong>First Name:</strong> {app.firstName}</div>
                                            <div className="mb-2"><strong>Last Name:</strong> {app.lastName}</div>
                                            <div className="mb-2"><strong>Income:</strong> {app.income}</div>
                                            <div className="mb-2"><strong>Expenses:</strong> {app.expenses}</div>
                                            <div className="mb-2"><strong>Assets:</strong> {app.assets}</div>
                                            <div className="mb-2"><strong>Liabilities:</strong> {app.liabilities}</div>
                                            <Button onClick={handleCloseDetails} className="bg-red-600 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline">
                                                Close
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                    <ConfirmationModal
                        isOpen={!!deleteConfirmationId}
                        onCancel={() => setDeleteConfirmationId(null)}
                        onConfirm={handleDelete}
                    />
                </div>
            </div>
        </div>
    );
};

export default FinanceList;
